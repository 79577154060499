/* eslint-disable max-statements */
import { UTAG_URL, UTAG_EMULATION_URL, UTAG_RESELLER_URLS, UTAG_DEFAULT_RESELLER_URL } from '../config';

/**
 * Determines appropriate reseller UTAG url based on environmental parameters
 * @TODO Remove this method and rely on headers to provide the correct UTAG url via window.ux.privacy.scripts?
 *
 * @returns {string} UTAG url
 */
export function getResellerUtagUrl() {
  switch (window.ux?.data?.env) {
    case 'production':
    case 'prod': return UTAG_RESELLER_URLS.prod;

    case 'test': return UTAG_RESELLER_URLS.test;

    case 'development':
    case 'dev': return UTAG_RESELLER_URLS.dev;

    default: return UTAG_DEFAULT_RESELLER_URL || UTAG_RESELLER_URLS.prod;
  }
}

/**
 * Determines appropriate UTAG url based on configuration and PLID
 *
 * @TODO Include detection of Chinese market to pull in chinese specific TIQCDN urls
 * @TODO begin utilizing headers and window.ux.privacy.scripts to override tealium urls
 *
 * @returns {string} UTAG url
 */
export function getUtagUrl() {
  const emulateUtag = Boolean(window.ux?.privacy?.emulateUtag);
  // Always assume plid is 1 if it is either not present or somehow malformed
  const plid = Number(window.ux?.data?.privateLabelId) || 1;

  if (plid !== 1) {
    return getResellerUtagUrl();
  }
  //
  // The following logic is to determine the correct UTAG url to use based on (a) configuration
  // via window.ux.privacy.emulateUtag and (b) PLID.
  //
  // Regardless of emulation status, if the PLID is NOT 1 (aka, the app is a reseller of any form)
  // we need to load in the "gpl/main" profile of the legacy Tealium tag, known here as
  // "UTAG_RESELLER_URL".
  //
  // Thanks to @jvogt to helping us track down the need for the reseller url.
  return emulateUtag ? UTAG_EMULATION_URL : UTAG_URL;
}

/**
 * Load Tealium (utag.js) file on demand
 *
 * @param {Record<number, 0 | 1>} consents categories -> numeric boolean for
 * what categories have been opted out
 * @returns {Promise<void>} A promise that resolves when the script has loaded
 * @public
 */
export function loadUtag(consents) {
  // If utag.js has already been loaded, return a resolve promise
  if (window.utag) return Promise.resolve();

  // Otherwise, return a promise that will resolve once the script is loaded
  return new Promise((resolve, reject) => {
    const scripts = window.ux?.privacy?.scripts || {
      0: [getUtagUrl()]
    };
    for (const [category, hrefs] of Object.entries(scripts)) {
      if (consents[category] !== 1) {
        for (const href of hrefs) {
          try {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = href;
            script.onload = resolve;
            document.head.appendChild(script);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            reject(err);
          }
        }
      }
    }
  });
}

/**
 * viewUtag function will load utag.js and export utag object to window.
 *
 * @param {Record<number, 0 | 1>} consents categories -> numeric boolean for
 * what categories have been opted out
 * @public
 */
export async function viewUtag(consents) {
  try {
    // With awaits, this code waits for the promise to resolve
    // If rejected, it will throw and go into catch
    await loadUtag(consents);

    window.utag.view(window.utag.data);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}
