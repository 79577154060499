import koekiemonster from 'koekiemonster';
const cookie = koekiemonster();

export default class Cookie {
  /**
   * Create a cookie manager for the specified cookie
   *
   * @param {Object} opts Options
   *
   * @param {string} opts.name The name of the cookie
   * @param {string} [opts.value] The value of the cookie
   * @param {string} [opts.domain] Optional domain with which to set any cookie value
   * @param {number} [opts.path=/] The HTTP path of the cookie
   * @param {number} [opts.minutes=525600] The number of minutes before the cookie should expire
   * @public
   */
  constructor({ name, value, domain, path = '/', minutes = 525600 }) {
    this.name = name;
    this.minutes = minutes;
    this.path = path;

    if (typeof domain !== 'undefined') this.domain = domain;
    if (typeof value !== 'undefined') this.value = value;
  }

  /**
   * Read the present value of the cookie
   *
   * @returns {string} The cookie value
   * @public
   */
  read() {
    this.value = cookie.getItem(this.name);
    return this.value;
  }

  /**
   * Read the present value of the cookie as JSON
   *
   * @returns {any} Parsed JSON value of the cookie. Returns undefined if the cookie contains invalid JSON.
   * @public
   */
  readJson() {
    const value = this.read();
    try {
      return JSON.parse(value);
    } catch (ex) {
      return {};
    }
  }

  /**
   * Used to set a cookie on the current base domain
   *
   * @param {Object} opts Options
   * @param {string} [opts.value]   The value of the cookie to set
   * @param {number} [opts.minutes] The number of minutes that the cookie should stay active until
   * @public
   */
  write({ value, minutes } = {}) {
    if (typeof value !== 'undefined')   this.value = value;
    if (typeof minutes !== 'undefined') this.minutes = minutes;

    cookie.setItem(this.name, this.value, this.attributes);
  }

  /**
   * Delete the specified cookie associated with this instance
   *
   * @public
   */
  remove() {
    cookie.removeItem(this.name, this.attributes);
  }

  /**
   * Cookie attributes for this instance beyond { name, value }.
   *
   * @type {Object} Cookie attributes { path, expires, [domain] }
   */
  get attributes() {
    const { path, domain } = this;
    const expires = this.getCookieExpiry(this.minutes);

    const attrs = { expires, path };
    if (domain) attrs.domain = domain;

    return attrs;
  }

  /**
   * Get the cookie expiration time for the specified number of minutes from now
   *
   * @param {number} minutes The number of minutes from now that the cookie should expire
   * @returns {string} The correctly formatted date/time string for the specified expiration
   * @protected
   */
  getCookieExpiry(minutes) {
    const d = new Date();
    d.setTime(d.getTime() + (minutes * 60 * 1000));

    return d.toGMTString();
  }
}

/**
 * Returns the subdomain free portion of the
 * FQDN provided by the url (i.e. url.hostname)
 *
 * Prefer this approach since static class methods
 * not supported in our browser matrix:
 *
 * https://caniuse.com/mdn-javascript_classes_static
 *
 * YMMV but 👎 polyfill overhead for a single useage
 *
 * @param {URL} url WHATWG URL to return root domain for
 * @returns {string} Root domain for the url
 */
Cookie.rootDomainFor = function (url) {
  const { hostname } = url;
  // Support second-level TLDs within target FQDNs
  const slicer = hostname.endsWith('.co.uk') ? -3 : -2;
  return hostname.split('.').slice(slicer).join('.');
};
