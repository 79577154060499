import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PrivacyManager from './privacy-manager';

/**
 * Top-level component responsible for acquiring & managing consent
 * across multiple geographies.
 *
 * @param {Visitor} visitor Page viewer for which consents are updating
 * @param {object} banner The banner view
 * @param {string} consentType The type to display banner
 * @param {object} i18n The localization strings object
 * @param {string} privacyPolicyUrl The URL to get more details about privacy policy
 * @param {boolean} showBanner Flag If banner should display or not
 * @param {boolean} showManager Flag If privacy manager should display or not
 * @returns {View} The privacy manager module
 * @public
 */
export default function ConsentBroker({
  visitor,
  banner,
  i18n,
  privacyPolicyUrl,
  showBanner,
  showManager
}) {
  const [managerOpen, setManagerOpen] = useState(showManager);
  const privacyProps = {
    visitor,
    i18n,
    open: managerOpen,
    setOpen: setManagerOpen
  };

  const Banner = banner?.Component;
  const bannerProps = {
    visitor,
    eids: banner?.eids,
    i18n,
    privacyPolicyUrl,
    onClose: ({ action } = {}) => {
      if (action === 'manage') {
        setManagerOpen(true);
      }
    }
  };

  useEffect(() => {
    //
    // Setup public facing API
    //
    window.ux = window.ux || {};
    window.ux.privacy = window.ux.privacy || {};
    window.ux.privacy.showManager = function () {
      setManagerOpen(true);
    };
  }, [managerOpen]);

  return <>
    { managerOpen && <PrivacyManager { ...privacyProps } /> }
    { showBanner && banner && <Banner { ...bannerProps }/> }
  </>;
}

ConsentBroker.propTypes = {
  visitor: PropTypes.object.isRequired,
  banner: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  privacyPolicyUrl: PropTypes.string,
  showBanner: PropTypes.bool,
  showManager: PropTypes.bool
};
